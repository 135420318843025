import React from "react"
import Slider from "./slider"
import Location from "./location"
import Partner from "./partner"

const homePage = () => {
  return (
    <>
      <Slider />
      <Location />
      <Partner />
    </>
  )
}

export default homePage
