import React from "react"

/* 
import partnerone from "../../img/partner/style2/1.png" */
import partnertwo from "../../img/partner/style2/2.png"
import partnerthree from "../../img/partner/style2/3.png"
import partnerfour from "../../img/partner/style2/4.png"
import partnerfive from "../../img/partner/style2/5.png"
import partnersix from "../../img/partner/style2/6.png"
import partnerseven from "../../img/partner/style2/7.png"

/* import partnerseven from "../../img/partner/style2/7.png" */

const Sliderabout = () => {
  return (
    <>
      <section>
        <div className="rs-partner partner-main-home partner-modify9">
          <div className="container">
            <div className="partner-content-widget">
              <div
                className="rs-carousel owl-carousel"
                data-loop="true"
                data-items="5"
                data-margin="30"
                data-autoplay="true"
                data-hoverpause="true"
                data-autoplay-timeout="5000"
                data-smart-speed="800"
                data-dots="false"
                data-nav="false"
                data-nav-speed="false"
                data-md-device="5"
                data-md-device-nav="false"
                data-md-device-dots="false"
                data-center-mode="false"
                data-ipad-device2="3"
                data-ipad-device-nav2="false"
                data-ipad-device-dots2="false"
                data-ipad-device="3"
                data-ipad-device-nav="false"
                data-ipad-device-dots="false"
                data-mobile-device="2"
                data-mobile-device-nav="false"
                data-mobile-device-dots="false"
              >
                <div className="owl-item">
                  <div className="logo-img">
                    <a href="https://devsdesign.net">
                      <img
                        className="hovers-logos rs-grid-img"
                        src={partnertwo}
                        title=""
                        alt=""
                      />
                      <img
                        className="mains-logos rs-grid-img "
                        src={partnertwo}
                        title=""
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="owl-item">
                  <div className="logo-img">
                    <a href="https://devsdesign.net">
                      <img
                        className="hovers-logos rs-grid-img"
                        src={partnerthree}
                        title=""
                        alt=""
                      />
                      <img
                        className="mains-logos rs-grid-img "
                        src={partnerthree}
                        title=""
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="owl-item">
                  <div className="logo-img">
                    <a href="https://devsdesign.net">
                      <img
                        className="hovers-logos rs-grid-img"
                        src={partnerfour}
                        title=""
                        alt=""
                      />
                      <img
                        className="mains-logos rs-grid-img "
                        src={partnerfour}
                        title=""
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="owl-item">
                  <div className="logo-img">
                    <a href="https://devsdesign.net">
                      <img
                        className="hovers-logos rs-grid-img"
                        src={partnerfive}
                        title=""
                        alt=""
                      />
                      <img
                        className="mains-logos rs-grid-img "
                        src={partnerfive}
                        title=""
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="owl-item">
                  <div className="logo-img">
                    <a href="https://devsdesign.net">
                      <img
                        className="hovers-logos rs-grid-img"
                        src={partnersix}
                        title=""
                        alt=""
                      />
                      <img
                        className="mains-logos rs-grid-img "
                        src={partnersix}
                        title=""
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="owl-item">
                  <div className="logo-img">
                    <a href="https://devsdesign.net">
                      {/*  <img
                        className="hovers-logos rs-grid-img"
                        src={partnerseven}
                        title=""
                        alt=""
                      />
                      <img
                        className="mains-logos rs-grid-img "
                        src={partnerseven}
                        title=""
                        alt=""
                      /> */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Sliderabout
