import React from "react"

import contacticonone from "../../img/contact/style1/icons/1.png"
import contacticontwo from "../../img/contact/style1/icons/2.png"
import contacticonthree from "../../img/contact/style1/icons/3.png"

const Sliderabout = () => {
  return (
    <>
      <section>
        <div className="rs-contact contact-style6 pb-120 md-pb-80">
          <div className="row no-gutters">
            <div className="col-lg-4">
              <div className="contact-map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2605.736360487591!2d-123.04127698444219!3d49.22452677932498!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486776d5b457f7d%3A0xaf0aa3147a7d7cc7!2sBradiance%20Project%20Management%20Consultants!5e0!3m2!1sen!2sin!4v1669781546538!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contact-section">
                <div className="contact-wrap">
                  <div className="sec-title mb-60">
                    <h2 className="title title4 ser-style4">Get in touch</h2>
                  </div>
                  <div id="form-messages"></div>
                  <form id="contact-form" method="post" action="mailer.php">
                    <fieldset>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                          <input
                            className="from-control"
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Name"
                            required=""
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                          <input
                            className="from-control"
                            type="text"
                            id="email"
                            name="email"
                            placeholder="E-Mail"
                            required=""
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                          <input
                            className="from-control"
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder="Phone Number"
                            required=""
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                          <input
                            className="from-control"
                            type="text"
                            id="website"
                            name="website"
                            placeholder="Your Website"
                            required=""
                          />
                        </div>
                        <div className="col-lg-12 mb-30">
                          <textarea
                            className="from-control"
                            id="message"
                            name="message"
                            placeholder="Your Message Here"
                            required=""
                          ></textarea>
                        </div>
                      </div>
                      <div className="btn-part">
                        <div className="form-group mb-0">
                          <input
                            className="readon more submit sub-con"
                            type="submit"
                            value="Submit Now"
                          />
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-4 contact-project"></div>
          </div>

          <div className="rs-contact pt-5 md-pt-4 py-4">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 md-mb-30">
                  <div className="contact-box">
                    <div className="contact-icon">
                      <img src={contacticonone} alt="images" />
                    </div>
                    <div className="content-text">
                      <h4 className="title">
                        <a href="/">Office address</a>
                      </h4>
                      <p className="services-txt">
                        3192 East 49th Avenue #6545, Vancouver, BC V5S 1L1,
                        Canada
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 sm-mb-30">
                  <div className="contact-box">
                    <div className="contact-icon">
                      <img src={contacticontwo} alt="images" />
                    </div>
                    <div className="content-text">
                      <h4 className="title">
                        <a href="/">Email us</a>
                      </h4>
                      <span>
                        <a href="mailto:info@bradiancepmc.com">
                          info@bradiancepmc.com
                        </a>
                      </span>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="contact-box">
                    <div className="contact-icon">
                      <img src={contacticonthree} alt="images" />
                    </div>
                    <div className="content-text">
                      <h4 className="title">
                        <a href="/">Call us</a>
                      </h4>
                      <span>
                        <a href="tel:(604) 446-9239">(604) 446-9239</a>
                      </span>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Sliderabout
